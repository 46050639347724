import React from "react";
import "./home.scss";
import Screen from "../../components/screen/screen";

export default function Home() {
  return (
    <Screen name="home">
      <h1>Home Screen</h1>
    </Screen>
  );
}
